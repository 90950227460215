import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  PageContextInfo, LayoutShell, 
  IS_LANG_ENABLED, ThemeProvider, LanguageToggleLink, 
  LangStringsProvider, LayoutsProvider, 
  FocusElementProvider, useEmbedComponents, 
  EmbedComponentsProvider, SiteFooterLayout, AppStateProvider, SiteHeaderProvider, 
} from '@shapeable/web';

import { VERY_LIGHT_BROWN, GREEN } from '../theme';
import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import { LANG_STRINGS } from '../lang';
import { PostsProvider } from '../gatsby/providers/posts-provider';
import { MenusProvider } from '../gatsby/providers/menus-provider';
import { OrganisationPartnersProvider } from "../gatsby/providers/organisation-partners-provider";
import { LayoutStyles, themeOverrides } from '../theme';
import { defaultAppState } from '../hooks/use-app-state';
// import { SiteHeaderLayout } from './entities/site-header-layout';

export type LayoutPropTypes = Classable & HasChildren & {
  path?: string;
  data?: any;
  pageContext?: PageContextInfo;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

// -------- Styles --------> 

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles}
  `,
});

const FooterStyles = breakpoints({
  base: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});


// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
  Footer: styled(SiteFooterLayout)`${FooterStyles}`,

};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children } = props;

  const embedComponents = useEmbedComponents();

  const ref = React.useRef();
  const focusRef = React.useRef();

  return (
    <AppStateProvider value={defaultAppState}>
    <FocusElementProvider value={focusRef}>
    <EmbedComponentsProvider value={{
      ...embedComponents,
    }}>
    <PostsProvider>
    <MenusProvider>
    <OrganisationPartnersProvider>
    <LangStringsProvider value={LANG_STRINGS}>
    <LayoutsProvider value={{ pageLayouts, sliceLayouts }}>
    <ThemeProvider overrides={themeOverrides} statusColor={GREEN}>
    {/* <SiteHeaderProvider value={{ component: SiteHeaderLayout }}> */}
      <My.Container
        id="#container"
        ref={ref}
        hideOverflow={false}
        className={className}
        includeTooltip
        modalColor={GREEN}
        tooltipProps={{
          backgroundColor: VERY_LIGHT_BROWN,
          globalEventOff: 'click',
        }}
      >
        <My.Body>
          {children}
        </My.Body>
        <My.Footer backgroundColor={VERY_LIGHT_BROWN} />
      </My.Container>
    {/* </SiteHeaderProvider> */}
    </ThemeProvider>
    </LayoutsProvider>
    </LangStringsProvider>
    </OrganisationPartnersProvider>
    </MenusProvider>
    </PostsProvider>
    </EmbedComponentsProvider>
    </FocusElementProvider>
    </AppStateProvider>
  );
  
  
};

Layout.defaultProps = LayoutDefaultProps;

