
import { PlainObject } from "@shapeable/types";
import { useAppState as useAppStateCore } from "@shapeable/web";

export type AppState = {
  isLangMenuVisible?: boolean;
  isShareMenuVisible?: boolean;
  isNavigationMenuVisible?: boolean;
};

export const defaultAppState: AppState = {
  isLangMenuVisible: false,
  isShareMenuVisible: false,
  isNavigationMenuVisible: false,
};

// not sure there's better way to auto-type this, but it'll do!
export const useAppState = () => useAppStateCore<AppState>();
