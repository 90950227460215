import { breakpoints, theme } from "@shapeable/theme";
import { css } from "styled-components";
import { Banner, Color } from "@shapeable/types";

export const CONTENT_BACKGROUND = '#FFF';

export const GREEN = '#3C5242';
export const LIGHT_GREEN = '#91A274';
export const TREE_GREEN = '#56A24D';
export const GRASS_GREEN = '#9AC356';
export const BLUE_GREEN = '#769291';
export const GREY = '#E6E5DF';
export const PEACH = '#F3B799';
export const SKY_BLUE = '#86BDE4';
export const GREY_BLUE = '#52636F';

export const BRAND_1 = GREEN;
export const BRAND_2 = LIGHT_GREEN;
export const BRAND_3 = TREE_GREEN;
export const BRAND_4 = GRASS_GREEN;
export const BRAND_5 = BLUE_GREEN;
export const BRAND_6 = GREY;
export const BRAND_7 = PEACH;
export const BRAND_8 = SKY_BLUE;
export const BRAND_9 = GREY_BLUE;


export const BRAND_COLORS = [BRAND_1, BRAND_2, BRAND_3, BRAND_4, BRAND_5, BRAND_6, BRAND_7, BRAND_8, BRAND_9];

export const LIGHT_BIEGE = '#F7F5F3';
export const VERY_LIGHT_BROWN = '#D5CDC4';
export const MEDIUM_BROWN = '#796c61';
export const DARK_BROWN = '#685c53';

export const EXTREMELY_LIGHT_BROWN = '#e6e1dc';

export const FOOTER_BACKGROUND = '#D5CDC4';

export const GREEN_OVERLAY = `${GREEN}AA`;


export const COLOR_TREE_GREEN: Color = {
  id: 'color-tree-green',
  name: 'Tree Green',
  slug: 'tree-green',
  value: TREE_GREEN,
};

export const COLOR_GREEN: Color = {
  id: 'color-green',
  name: 'Green',
  slug: 'green',
  value: GREEN,
};


export const BANNER_LEAF: Banner = {
  id: 'banner-leaf',
  name: 'Banner Leaf',
  slug: 'banner-leaf',
  image: {
    id: 'attneyYxGMrohNN2E',
    url: 'https://res.cloudinary.com/shapeable/image/upload/v1668989824/villars-institute/banner/sub-page-banner-leaf_image__sub-s1-banner_z8crca.jpg',
    width: 2540,
    height: 521,
  },
};


export const BANNER_EVENT: Banner = {
  id: 'banner-event',
  name: 'Banner Event',
  slug: 'banner-event',
  image: {
    id: 'recYRsg6ML0rqtefA_image',
    url: 'https://res.cloudinary.com/shapeable/image/upload/v1668989821/villars-institute/banner/sub-page-banner-event_image__event-banner.jpg',
    width: 1920,
    height: 576,
  },
};

export const BANNER_KNOWLEDGE_HUB: Banner = {
  id: 'banner-knowledge-hub',
  name: 'Banner Knowledge Hub',
  slug: 'banner-knowledge-hub',
  image: {
    id: 'recYRsg6ML0rqtefA_image',
    url: 'https://res.cloudinary.com/shapeable/image/upload/v1668989824/villars-institute/banner/sub-page-banner-leaf_image__sub-s1-banner_z8crca.jpg',
    width: 2540,
    height: 521,
  },
};




export const themeOverrides = {
  color: {
    primary: TREE_GREEN,
    secondary: LIGHT_GREEN,
    navigationActive: GREEN,
    link: TREE_GREEN,
    invertedLink: '#FFF',
    linkHover: GREEN,
    invertedLinkHover: PEACH,

    invertedMenuHover: '#FFF',
    invertedMenuActive: PEACH,
    invertedMenuHoverBackground: TREE_GREEN,
    
    
  },
};


export const FontSizeStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(16)};
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(16)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(17)};
  `
});


/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`
    ${FontSizeStyles};

    .shp--site-header-layout__navigation {
      font-size: 1.2em;
    }

    .shp--site-menu-bar {
      background: linear-gradient(to bottom, ${GREEN} 0%, ${GREEN}EE 100%), url(${BANNER_LEAF.image.url});
      background-size: cover;
    }
    
    .shp--site-menu-bar__title {
      font-size: ${theme.FONT_SIZE(18)}; 
    }

    .shp--session-layout__presenter {
      margin-top: -5px;
    }

    .shp--slice-intro .shp--markdown-content {
      font-size: 1em;
      text-align: left;
      line-height: 1.35em;
      font-weight: 300;
      max-width: 900px;
    }

    .shp--content-title {
      font-size: 24px;
    }

    .shp--organisation-powered-by {
      img {
        mix-blend-mode: color-burn;
        filter: grayscale(1);
      }

      &:hover {
        filter: none;
      }
    }
  `,
  tablet: css`
    .shp--slice-intro .shp--markdown-content {
      font-size: 1.5em;
    }
  `,
  desktop: css`
    .shp--site-menu-bar {
      opacity: 0.97;
    }
  `
}); 
