import { Page } from "@shapeable/types";
import { COLOR_WHITE } from "@shapeable/web";
import { COLOR_GREEN, COLOR_TREE_GREEN } from "./theme";

/* Canned entities to speed things up a bit */


export const createIntroSlice = (entity: Page) => ({
  id: 'intro-slice',
  intro: entity.intro,
  backgroundColor: COLOR_GREEN,
  color: COLOR_WHITE,
  layout: {
    id: 'slice-layout-intro',
    slug: 'slice-layout-intro',
    component: 'SliceLayoutIntro',
  }
});
