import React from 'react';
import styled, { css } from 'styled-components';
import { ContentEntity, PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutStandard, SliceLayoutFeaturedPosts, useFeedEntries, usePosts } from '@shapeable/web';
import { SliceLayoutColorStrip, SliceLayoutIntro } from '../slice-layouts';
import { createIntroSlice } from '../../data';

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`
    /* override title styles via BEM here to affect spacing */ 
    .shp--page-layout-standard__title {
      font-weight: 300;
      line-height: 1.15em;
      text-transform: uppercase;
      margin-top: ${theme.UNIT(6)};
      margin-bottom: ${theme.UNIT(0)};
      font-family: ${theme.FONT('sans')};
      font-size: ${18/16}em;
    }

    .shp--page-layout-standard__subtitle {
      font-weight: 400;
      margin-bottom: ${theme.UNIT(10)};
      line-height: 1.15em;
      
      strong, b {
        font-weight: 500;
      }
    }
  `,
  tablet: css`
    .shp--page-layout-standard__title {
      font-size: ${35/15}em;
      line-height: 1.15em;
    }

    .shp--page-layout-standard__subtitle {
      font-size: 2.75em;
      margin-bottom: ${theme.UNIT(16)};
    }
  `,
  desktop: css`
    .shp--page-layout-standard__title {
      font-size: ${45/15}em;
      line-height: 1.15em;
    }

    .shp--page-layout-standard__subtitle {
      font-size: 2.5em;
      margin-bottom: ${theme.UNIT(30)};
    }
  `
});

// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,
};

export const PageLayoutHome: PageLayoutComponent = (props) => {
  const { className, entity } = props;

  // TODO - once upgraded, convert these to real CMS slices
  const introSlice = createIntroSlice(entity);

  const slices = <>
    <SliceLayoutColorStrip entity={entity} />
    <SliceLayoutIntro entity={introSlice} />
    <SliceLayoutFeaturedPosts entity={entity} />
    {props.slices}
  </>
  
  return (
    <My.Layout 
      entity={entity}
      slices={slices}
      headerProps={{ showOverlayGradient: true, aspectRatio: { base: 4/3, landscape: 21 / 9, tablet: 21 / 9 }, showSlideControls: true }}
      className={className}
    />
  )
};
