import { LangStrings } from "@shapeable/web";

export const LANG_STRINGS: LangStrings = {
  fr: {
    "Please check the I'm not a robot checkbox to validate you are human"     : "Veuillez cocher la case « Je ne suis pas un robot » pour confirmer que vous êtes une personne.",
    "Please enter your given name."                                           : "Veuillez entrer votre prénom.",
    "Please enter your family name."                                          : "Veuillez entrer votre nom de famille.",
    "Please provide the details of your request in the Message field."        : "Veuillez spécifier votre demande dans le champ « Message ».",
    "Please check that your email address is VALID"                           : "Veuillez vérifier que votre adresse électronique est VALIDE.",
    "Please provide a VALID URL in the Company Website URL field"             : "Veuillez fournir une URL VALIDE dans le champ URL du site Web de la société.",
    "Please confirm you agree with our terms and privacy policy"              : "Veuillez confirmer que vous acceptez nos conditions et notre politique de confidentialité.",

    "monthNames": "Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre",
    "monthNamesShort": "Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.",
    
    "%s - The Villars Institute"                                              : "%s - Le Villars Institute",
    "The Villars Institute"                                                   : "Le Villars Institute",
    "Our Partners"                                                            : "Nos partenaires",
    "Latest Articles & News"                                                  : "Derniers articles et parutions",
    "Knowledge Hub"                                                           : "Pôle de connaissances",
    "Visit Our Knowledge Hub"                                                 : "Visitez le pôle de connaissances",

    "Do MMM YYYY"                                                             : "D MMM YYYY",
    "Do MMM"                                                                  : "D MMM",
    "Please wait..."                                                          : "Veuillez patienter svp...",
    "Thankyou, your submission has been received."                            : "Merci, votre demande a bien été reçue.",
    "We will be in touch soon."                                               : "Nous vous contacterons dans les meilleurs délais.",

    "Post"                                                                    : "Article",
    "Article"                                                                 : "Article",
    "Press Release"                                                           : "Communiqué de presse",
    "Given Name (Required):"                                                  : "Prénom (Obligatoire):",
    "Family Name (Required):"                                                 : "Nom de famille (obligatoire):",
    "Email:"                                                                  : "E-mail:",
    "Company:"                                                                : "Société:",
    "Job Title:"                                                              : "Intitulé du poste:",
    "Message:"                                                                : "Message:",
    "I would like to become a %s member"                                      : "Je souhaite devenir membre %s",
    "I\'d like to receive updates from %s"                                     : "J'aimerais recevoir des mises à jour de %s",
    "I confirm that I have read and agree to the [terms](%s) and [privacy policy](%s) of %s." : "Je confirme avoir lu et accepté [les conditions](%s) et [la politique de confidentialité](%s) du %s.",
    "Sorry, we need a bit more information:"                                  : "Désolé, nous avons besoin d'un peu plus d'informations:",
    "Submit"                                                                  : "Soumettre",
    
    
    "read more"                                                               : "lire la suite",
    "Discover More"                                                           : "En savoir plus",
    "Home"                                                                    : "Accueil",
    "Bio"                                                                     : "Bio",
    "Show Contact Form"                                                       : "Afficher le formulaire de contact.",
    "Hide Contact Form"                                                       : "Masquer le formulaire de contact.",

    "%s %s. All rights reserved"                                              : "%s %s. Tous droits réservés.",
    "Published:"                                                              : "Publié:",
    "Author:"                                                                 : "Écrit par:",

    "Written By:"                                                             : "Écrit par:",
    "Edited:"                                                                 : "Édité:",
    
    "Download Info PDF"                                                       : "Télécharger le PDF d'informations",
    "Apply"                                                                   : "Postuler",

    "Morning"                                                                 : "Le Matin",
    "Afternoon"                                                               : "Après-midi",
    "Evening"                                                                 : "Le Soir",

    "Agenda"                                                                 : "Ordre du jour",
    "Useful Information"                                                     : "Informations utiles",
    "Safeguarding Rules"                                                     : "Règles de sauvegarde",

    "Welcome"                                                                : "Bienvenue",

    "Explore The Venue"                                                      : "Parcourez les lieux",
    "View On Google Maps"                                                    : "Afficher sur Google Maps",
    "View Agenda"                                                            : "Ordre du Jour",
  }
};